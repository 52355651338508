import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Link from "@components/Link";
import { Box, GridListTile, Card, CardMedia } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import YouTubeIcon from "@material-ui/icons/YouTube";
import { useTranslation } from "react-i18next";
import Image from "next/image";


function FooterCopyright() {
  const classes = useStyles();

  return (
    <div className={classes.footer_copyright}>
      <Container className={classes.main_container}>
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
            height: "100%",
          }}
        >
          <Typography variant="body2" style={{ color: "#969696" }}>
            {"© "}
            <Link color="inherit" href="https://parityplatform.com/">
              PARITY PLATFORM
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
          </Typography>
          <div style={{ marginRight: "50px" }}>
            <IconButton
              className={classes.FacebookIcon}
              onClick={() => window.open("https://www.facebook.com/evloader")}
              aria-label="Facebook"
            >
              <FacebookIcon color="inherit" />
            </IconButton>
            <IconButton
              className={classes.LinkedInIcon}
              onClick={() =>
                window.open("https://gr.linkedin.com/company/parity-platform")
              }
              aria-label="Linkedin"
            >
              <LinkedInIcon color="inherit" />
            </IconButton>
            <IconButton
              className={classes.InstagramIcon}
              onClick={() => window.open("https://www.instagram.com/evloader/")}
              aria-label="Instagram"
            >
              <InstagramIcon color="inherit" />
            </IconButton>
            <IconButton
              className={classes.TwitterIcon}
              onClick={() =>
                window.open("https://twitter.com/parityplatform?lang=en")
              }
              aria-label="Twitter"
            >
              <TwitterIcon color="inherit" />
            </IconButton>
            <IconButton
              className={classes.YouTubeIcon}
              onClick={() =>
                window.open(
                  "https://www.youtube.com/channel/UCRRs_9Kz8fDt3UpSZE3RT-w"
                )
              }
              aria-label="Youtube"
            >
              <YouTubeIcon color="inherit" />
            </IconButton>
          </div>
        </Grid>
      </Container>
    </div>
  );
}

function FooterMainBlock() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.footer_main}>
      <Container className={classes.main_container} maxWidth="lg">
        <Grid item xs={12} sm={6} md={2}>
          <Box className={classes.box}>
            <Typography variant="h6" style={{ color: "#fff" }}>
              {t("Εταιρεία")}
            </Typography>
            <br />
            <Link href="/contact" className={classes.link}>
              {t("Επικοινωνία")}
            </Link>
            <br />
            <Link href="/about" className={classes.link}>
              {t("Σχετικά")}
            </Link>
            <br />
            <Link href="/help-center" className={classes.link}>
              {t("Help Center")}
            </Link>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={2}>
          <Box className={classes.box}>
            <Typography variant="h6" style={{ color: "#fff" }}>
              {t("Ξεκινώντας")}
            </Typography>
            <br />
            <Link href="/station-owner/my-stations" className={classes.link}>
              {t("Προσθηκη σταθμού")}
            </Link>
            <br />
            <Link
              target="_blank"
              href="https://shop.evloader.com/"
              className={classes.link}
            >
              {t("Αγορά Φορτιστή")}
            </Link>
            <br />
            <Link href="/register" className={classes.link}>
              {t("Εγγραφή")}
            </Link>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={2}>
          <Box className={classes.box}>
            <Typography variant="h6" style={{ color: "#fff" }}>
              {t("Νομικά")}
            </Typography>
            <br />
            <Link href="/termsofuse" className={classes.link}>
              {t("Όροι και Προϋποθέσεις")}
            </Link>
            <br />
            <Link href="/privacy" className={classes.link}>
              {t("Πολιτική Απορρήτου")}
            </Link>
            <br />
            <Link href="/attributions" className={classes.link}>
              Attributions
            </Link>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={2} style={{ marginRight: "45px" }}>
          <form
            action="https://parityplatform.us18.list-manage.com/subscribe/post?u=587cc15349f9bd97153ad2d7f&amp;id=db05d2bc3b"
            method="post"
            id="mc-embedded-subscribe-form"
            name="mc-embedded-subscribe-form"
            target="_blank"
            noValidate
          >
            <div>
              <h2 style={{ color: "white" }}>Newsletter</h2>
              <div>
                <label htmlFor="mce-EMAIL" style={{ color: "#969696" }}>
                  Email{" "}
                </label>
                <input
                  type="email"
                  className="email"
                  name="EMAIL"
                  id="mce-EMAIL"
                  style={{ width: "100%", padding: 8, marginTop: 5 }}
                ></input>
              </div>
              <div id="mce-responses">
                <div style={{ display: "flex", marginTop: "17px" }}>
                  <input
                    className={classes.subscribe}
                    type="submit"
                    value="Subscribe"
                    name="subscribe"
                  ></input>
                </div>
              </div>
            </div>
          </form>
        </Grid>

        <Grid item xs={12} sm={6} md={2}>
          <Box className={classes.box}>
            <div style={{ display: "flex" }}>
              <GridListTile style={{ marginRight: "10px" }} component="span">
                <Typography variant="h6" style={{ color: "#fff" }}>
                  {t("Supported by")}
                </Typography>
                <br />
                <Card className={classes.image_card}>
                  <Image
                    src="/images/EIT-CKIC-Logo_Transparent_Standard(1).png"
                    width={160}
                    height={88}
                    title="EIT Climate-KIC"
                    alt="EIT Climate-KIC"
                    objectFit="cover"
                    quality="10"
                  />
                </Card>
              </GridListTile>

              <GridListTile style={{ marginRight: "10px" }} component="span">
                <Typography variant="h6" style={{ color: "#fff" }}>
                  {t("Member of")}
                </Typography>
                <br />
                <Card className={classes.image_card}>
                  <Image
                    src="/images/RED-Athens-Up and Running-DG.jpg"
                    width={160}
                    height={70}
                    title="Athens Impact Hub"
                    alt="Athens Impact Hub"
                    objectFit="cover"
                    quality="10"
                  />
                </Card>
              </GridListTile>
            </div>
          </Box>
        </Grid>
      </Container>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
    alignSelf: "flex-end",
  },
  footer_copyright: {
    display: "flex",
    backgroundColor: "#222",
    alignItems: "center",
    width: "100%",
  },
  footer_main: {
    backgroundColor: "#2c2c2c",
    width: "100%",
    display: "flex",
  },
  main_container: {
    display: "flex",
    flexWrap: "wrap",
    maxWidth: "1400px",
    height: "100%",
  },
  footer: {
    position: "absolute",
    right: 0,
    left: 0,
  },
  box: {
    paddingTop: "20px",
    marginBottom: "20px",
  },
  subscribe: {
    backgroundColor: "#f18d0f",
    width: "40%",
    border: "transparent",
    borderRadius: "3px",
    height: "40px",
    color: "white",
    "&:hover": {
      backgroundColor: "#A8620A",
      cursor: "pointer",
    },
    marginBottom: "20px",
  },
  link: {
    color: "#969696",
    "&:hover": {
      color: "#f18d0f",
    },
    textDecoration: "none",
  },
  FacebookIcon: {
    height: "45px",
    width: "45px",
    color: "#616161",
    borderRadius: "0px",
    "&:hover": {
      backgroundColor: "#1e77f0",
      color: "white",
    },
    marginRight: theme.spacing(1),
  },
  TwitterIcon: {
    height: "45px",
    width: "45px",
    color: "#616161",
    borderRadius: "0px",
    "&:hover": {
      backgroundColor: "#55acee",
      color: "white",
    },
    marginRight: theme.spacing(1),
  },
  LinkedInIcon: {
    height: "45px",
    width: "45px",
    color: "#616161",
    borderRadius: "0px",
    "&:hover": {
      backgroundColor: "#117eb9",
      color: "white",
    },
    marginRight: theme.spacing(1),
  },
  InstagramIcon: {
    height: "45px",
    width: "45px",
    color: "#616161",
    borderRadius: "0px",
    "&:hover": {
      backgroundImage: "url(images/ig_color.jpg)",
      backgroundSize: "100% 100%",
      color: "white",
    },
    marginRight: theme.spacing(1),
  },
  YouTubeIcon: {
    height: "45px",
    width: "45px",
    color: "#616161",
    borderRadius: "0px",
    "&:hover": {
      backgroundColor: "#ff5722",
      color: "white",
    },
    marginRight: theme.spacing(1),
  },
  image_card: {
    width: "160px",
    height: "98px",
    padding: 5,
    boxShadow: "none",
    marginBottom: "20px",
    borderRadius: "2px",
    backgroundColor: "#fffffe",
  },
}));

export default function StickyFooter() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Grid container className={classes.footer}>
        <FooterMainBlock />
        <FooterCopyright />
      </Grid>
    </div>
  );
}
